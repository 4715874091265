var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full p-4" },
    [
      _c(
        "vs-row",
        { staticClass: "mb-2" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c("ComplexEditor", {
                attrs: {
                  editor_data: _vm.commentData,
                  placeholder: _vm.$t("escreva-o-seu-comentario-aqui"),
                },
                on: {
                  "update:editor_data": function ($event) {
                    _vm.commentData = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "vs-row",
        { staticClass: "mb-5" },
        [
          _c(
            "vs-col",
            { staticClass: "w-full" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "float-right",
                  attrs: { color: "primary", disabled: !_vm.commentData },
                  on: { click: _vm.comment },
                },
                [_vm._v(" " + _vm._s(_vm.$t("comentar")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.comments
        ? _c("h5", { staticClass: "mt-2 mb-4 border-bottom" }, [
            _vm._v(_vm._s(_vm.commentAmount)),
          ])
        : _vm._e(),
      !_vm.isEmpty(_vm.comments) && _vm.comments.length > 0
        ? _c(
            "vs-row",
            [
              _c(
                "vs-col",
                [
                  _c("VxTimeline", {
                    attrs: { data: _vm.comments, html: true },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "vs-row",
            [
              _c(
                "vs-col",
                { staticClass: "flex items-center justify-center" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("ainda-nao-ha-comentarios-para-esta-questao")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }